import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MbLoadingBarService } from '@montblancsimpl/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

/**
 * Adds the loading bar service to every http call
 */
@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private loadingBarService: MbLoadingBarService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingBarService.start();
    return next.handle(request.clone()).pipe(
      finalize(() => {
        this.loadingBarService.complete();
      })
    );
  }
}
