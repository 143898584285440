export enum AppRoute {
  Forbidden = 'forbidden',
  Example = 'example',
  Roles = 'roles',
  Documents = 'documents',
  Candidate = 'candidate',
  Candidates = 'candidates',
  Pages = 'pages',
  Users = 'users'
}
