import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { MbResponse, MbUser } from '@montblancsimpl/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    public httpClient: HttpClient
  ) { }

  getLoggedInUser(): Observable<MbResponse<MbUser>> {
    return this.httpClient
        .get<MbResponse<MbUser>>(
            `${environment.apiUrl}/umc/users/me`,
        )
        .pipe(
            map(response => {
                return response;
            })
        );
  }


  getPermissionsByLoggedInUserByApplication(appliaction: string): Observable<MbResponse<string[]>> {
    return this.httpClient
        .get<MbResponse<string[]>>(
            `${environment.apiUrl}/umc/users/me/permissions/${appliaction}`,
        )
        .pipe(
            map(response => {
                return response;
            })
        );
  }
}
