import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes, UrlMatchResult, UrlSegment } from '@angular/router';

import { Route, Permissions } from './core/route.service';
import { CandidateLayoutComponent } from './layouts/candidate-layout/candidate-layout.component';
import { AppRoute } from './shared/app.route.enum';
import { AdminGuard, AuthenticationGuard, CandidateGuard } from './core';

// Example forbidden component to which the user will be redirected to if
// he is not authenticated or authorized
@Component({
  template: `Forbidden`
})
export class ForbiddenComponent { }

export const routes: Routes = [
  // The base layout and authentication takes care of authentication
  // for every request. Use the authentication guard on lower routing levels
  // to enable permission based authorization (see below)
  { path: '', redirectTo: 'candidate', pathMatch: 'full' },

  {
    component: CandidateLayoutComponent,
    path: AppRoute.Candidate,
    children: [
      {
        path: '',
        canActivate: [AuthenticationGuard, CandidateGuard],
        loadChildren: () => import('app/candidate/candidate.module').then(m => m.CandidateModule)
      }
    ]
  },
  Route.withBaseLayoutAndAuthentication([
    {
      path: AppRoute.Roles,
      canActivate: [AuthenticationGuard, AdminGuard],
      data: { permissions: [ Permissions.Application.Admin ] },
      children: [
        {
          path: '',
          loadChildren: () => import('app/roles/roles.module').then(m => m.RolesModule)
        }
      ]
    },
    {
      path: AppRoute.Candidates,
      canActivate: [AuthenticationGuard, AdminGuard],
      data: { permissions: [ Permissions.Application.Admin ] },
      children: [
        {
          path: '',
          loadChildren: () => import('app/candidates/candidates.module').then(m => m.CandidatesModule)
        }
      ]
    },
    {
      path: AppRoute.Pages,
      canActivate: [AuthenticationGuard, AdminGuard],
      data: { permissions: [ Permissions.Application.Admin ] },
      children: [
        {
          path: '',
          loadChildren: () => import('app/pages/pages.module').then(m => m.PagesModule)
        }
      ]
    },
    {
      path: AppRoute.Documents,
      canActivate: [AuthenticationGuard, AdminGuard],
      data: { permissions: [ Permissions.Application.Admin ] },
      children: [
        {
          path: '',
          loadChildren: () => import('app/documents/documents.module').then(m => m.DocumentsModule)
        }
      ]
    },
    {
      path: AppRoute.Users,
      canActivate: [AuthenticationGuard],
      data: { permissions: [Permissions.Application.Candidate] },
      children: [
        {
          path: '',
          loadChildren: () => import('app/users/users.module').then(m => m.UsersModule)
        }
      ]
    }
  ]),
  {
    path: 'forbidden',
    component: ForbiddenComponent
  },
  { path: '**', redirectTo: 'candidates', pathMatch: 'full' } // Fallback route,
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
