<!-- 1st Row - LANGUAGE / TITLE / USER -->
<div class="nav-header-small-gray mat-typography">
  <div class="container flex-row flex-space-between">
    <!-- LANGUAGE -->
    <div class="flex-1 flex-row flex-start flex-items-center">
      
    </div>
    <!-- APPLICATION TITLE -->
    <div class="flex-row flex-center flex-items-center flex-1" style="padding-top:5px; ">
      <div class="title-hidden-xs">
      Welcome Page
      </div>&nbsp;{{ environment }}
    </div>
    <!-- LOGGED IN USER -->
    <div style="display: flex; flex-direction: row; justify-content: flex-end; align-items: flex-start; padding-top:5px; font-size: 13px;  flex:1">
      <div class="mat-typography user-menu" style="display: flex; justify-content: end; font-size: 13px;">
        <span>{{authenticationService?.credentials?.user?.fullName }}</span>
      </div>
      <div>
        <button [matTooltip]="'Logout'" (click)="logout()" mat-button style="display: flex; justify-content: center; align-items: center; height: 22px">
            <mat-icon style="font-size: 22px;">logout</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- 2nd row - LOGO -->
<div class="nav-header-large">
  <div class="container flex-row flex-center flex-items-center">
    <!-- POSSIBLE SIDE NAVIGATION -->
    <div class="flex-row flex-start flex-items-center" style="margin-left: -3px">
    </div>
    <!-- LOGO -->
    <div class="flex-row flex-center flex-items-center">
      <button mat-button class="no-hover" routerLink="">
        <img style="width: 146px;
        margin-top: 14px;" alt="Logo" src="../../../assets/Logo_MTB.png">
      </button>
    </div>
    <!-- SEARCH -->
    <div class="flex-row flex-end flex-items-center">
    </div>
  </div>

</div>
<!-- 3rd row - NAVIGATION -->
<div class="nav-header-small-black">
  <div class="container flex-row flex-center flex-items-center">
    <!-- NAVIGATION -->
    <div class="flex-center flex-items-center">

      <!-- CANDIDATES -->
      <a class="link" routerLink="/candidates" [routerLinkActive]="'link-active'">
        <button class="nav-button" mat-button disableRipple="true">
          <span>CANDIDATES</span>
        </button>
      </a>

      <!-- ROLES -->
      <a class="link" routerLink="/roles" [routerLinkActive]="'link-active'">
        <button class="nav-button" mat-button>
          <span>ROLES</span>
        </button>
      </a>
      <!-- DOCUMENTS -->
      <a class="link" routerLink="/documents" [routerLinkActive]="'link-active'">
        <button class="nav-button" mat-button>
          <span>DOCUMENTS</span>
        </button>
      </a>
      <!-- PAGES -->
      <a class="link" routerLink="/pages" [routerLinkActive]="'link-active'">
        <button class="nav-button" mat-button>
          <span>PAGES</span>
        </button>
      </a>
      <!-- USERS -->
      <a *ngIf="this.authenticationService.hasPermission('Admin')" class="link" routerLink="/users" [routerLinkActive]="'link-active'">
        <button class="nav-button" mat-button>
          <span>ADMINS</span>
        </button>
      </a>
    </div>
    <div class="flex-row flex-end flex-items-center">
    </div>
  </div>

</div>