import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { I18nService } from '@app/core';
import { Page } from '@app/shared/models/Page';
import { CandidateService } from '@app/shared/services/candidate.service';
import { environment } from '@env/environment';
import { MbBaseComponent } from '@montblancsimpl/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'wel-candidate-header',
  templateUrl: './candidate-header.component.html',
  styleUrls: ['./candidate-header.component.scss']
})
export class CandidateHeaderComponent extends MbBaseComponent implements OnInit {

  menuHidden = true;

  environment = environment.environmentName;
  pages$: Observable<Page[]>;

  constructor(
    private router: Router,
    private candidateService: CandidateService,
    public i18nService: I18nService
  ) {
    super();
  }

  ngOnInit() {
    this.pages$ = this.candidateService.getPages();
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }
}
