import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService, I18nService } from '@app/core';
import { environment } from '@env/environment';
import { MbAuthenticationService } from '@montblancsimpl/authentication';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  
  menuHidden = true;

  environment = environment.environmentName;

  constructor(
    private router: Router,
    public authenticationService: AuthenticationService,
    public i18nService: I18nService,
    private mbAuthenticationService: MbAuthenticationService,
  ) {}

  ngOnInit() {}

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  logout() {
    this.mbAuthenticationService.logout();
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }
}
