<!-- 1st Row - LANGUAGE / TITLE / USER -->
<div class="nav-header-small-gray mat-typography">
  <div class="container flex-row flex-space-between-center"     style="height: 100%; padding: 0px; justify-content: center; width: max-content;">

    <!-- APPLICATION TITLE -->
    <div class="flex-space-between-center flex-1-1-auto"    style="padding-top:3px">
      <span class="title-hidden-xs">
        Welcome Page
      </span>
      <span *ngIf="environment !== ''">
        &nbsp;{{ environment }}
      </span>
    </div>

  </div>
</div>
<!-- 2nd row - LOGO -->
<div class="nav-header-large">
  <div class="container flex-row flex-space-between-center" style="height: 100%; padding: 0px; justify-content: center; width: max-content;"   >

    <!-- LOGO -->
    <div class="flex-space-between-center flex-1-1-0"   >
      <button mat-button class="no-hover" routerLink="">
        <img style="width: 146px; margin-top: 14px; margin-bottom: 6px;" alt="Logo" src="../../../assets/Logo_MTB.png">
      </button>
    </div>
    <!-- MENU -->
    <div style="position: absolute; left: 0px; top: 10px;">
      <button mat-button class="language-mat-button align-right" [matMenuTriggerFor]="navigationMenu">
        <mat-icon style="font-size:25px">menu</mat-icon>
      </button>
    </div>

    <mat-menu #navigationMenu="matMenu">
      <!-- INTRO -->
      <a mat-menu-item routerLink="/candidate" routerLinkActive="link-active" [routerLinkActiveOptions]="{ exact: true }">
        <span>
          <span>{{ 'Candidate.Navigation.Intro' | translate }}</span>
        </span>
      </a>
      <!-- HOMEPAGE -->
      <a mat-menu-item routerLink="/candidate/pages" routerLinkActive="link-active" [routerLinkActiveOptions]="{ exact: true }">
        <span>
          <span>{{ 'Candidate.Navigation.Homepage' | translate }}</span>
        </span>
      </a>
      <a mat-menu-item *ngFor="let page of pages$ | async" routerLink="/candidate/page/{{ page.id }}"
        routerLinkActive="link-active">
        <span>
          <span>{{ page.title }}</span>
        </span>
      </a>

    </mat-menu>

  </div>

</div>
<!-- 3rd row - NAVIGATION -->
<!-- <div class="nav-header-small-black">
  <div class="container"  >

     NAVIGATION
    <div    fxShow.xs="false" fxHide.gt-xs="false" style="margin-top: -1px;">

      <button *ngFor="let page of pages$ | async" mat-button routerLink="/candidate/page/{{ page.id }}"
        routerLinkActive="link-active">
        <span>{{ page.title }}</span>
      </button>
    </div>

  </div>

</div> -->