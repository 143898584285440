import { inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { __, MbLoadingBarService, MbUser, MbUserService } from '@montblancsimpl/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, Subject, zip } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserService } from './user.service';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AppRoute } from '@app/shared/app.route.enum';

export class Credentials {
  user: MbUser;
  permissions: string[];
  expires: Date;
}

const credentialsKey = 'ruifasjkdfasiuzqei2387';

/**
 * Provides a base for authentication workflow.
 */
@Injectable()
export class AuthenticationService {

  private _credentials: Credentials | null;

  private _credentials$: Subject<Credentials> = new Subject<Credentials>();

  credentials$: Observable<Credentials> = this._credentials$.asObservable();

  constructor(
    private toastr: ToastrService,
    private userService: UserService,
    private router: Router,
    private loadingBarService: MbLoadingBarService,
  ) {
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey); 
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
      let expiryDate = null;
      try {
        expiryDate = new Date(this._credentials.expires);
      } catch (e) { }
      this._credentials.expires = expiryDate;
    }
  }

  hasPermission(permission: string): boolean {
    if (!this.isAuthenticated()) {
      return false;
    }
    return this.credentials.permissions.indexOf(permission) > -1;
  }

  hasAnyPermission(permissions: string[]): boolean {
    if (__.IsNullOrUndefined(permissions)) {
      return true;
    }
    if (!this.isAuthenticated()) {
      return false;
    }
    return this.credentials.permissions.some((permission: string) => {
      const s = '';
      return permissions.findIndex(a => permission === a) > -1;
    });
  }

  /**
   * Authenticates the user.
   * @param {MbUser} user The user that should be logged in.
   * @return {Observable<Credentials>} The user credentials.
   */
  login(): Observable<Credentials> {
    return zip(
      this.userService.getLoggedInUser().pipe(
        catchError((error: any) => {
          this.toastr.error('It seems like the server is not accessible. Please make sure that you are connected to the internet.');
          return of(null);
        })
      ),
      this.userService.getPermissionsByLoggedInUserByApplication(environment.umcApplicationKey).pipe(
        catchError((error: any) => {
          this.toastr.error('It seems like the server is not accessible. Please make sure that you are connected to the internet.');
          return of([]);
        })
      )
    ).pipe(
      catchError((error: any) => {
        return of([null, []]);
      }),
      map((userAndPermissionsResult: any) => {
        let credentials = null;

        if (__.IsNullOrUndefined(userAndPermissionsResult[0]) || __.IsNullOrUndefined(userAndPermissionsResult[1])) {
          credentials = <Credentials>{
            isErroneous: false,
            isLoading: false,
            user: null,
            permissions: [],
            expires: new Date()
          };
        } else {
          if (
            !__.IsNullOrUndefined(userAndPermissionsResult[0].data && userAndPermissionsResult[1].data.length !== 0)
          ) {
            credentials = <Credentials>{
              isErroneous: false,
              isLoading: false,
              user: userAndPermissionsResult[0].data,
              permissions: userAndPermissionsResult[1].data,
              expires: new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate(),
                new Date().getHours(),
                new Date().getMinutes() + environment.loginExpiryTime,
                new Date().getSeconds()
              )
            };
          } else {
            credentials = <Credentials>{
              isErroneous: false,
              isLoading: false,
              user: null,
              permissions: [],
              expires: new Date()
            };
          }
        }

        this.setCredentials(Object.assign(new Credentials(), credentials));

        return credentials;
      })
    );
  }

  /**
   * Logs out the user and clear credentials.
   * @return {Observable<boolean>} True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.setCredentials();
    return of(true);
  }

  /**
   * Checks is the user is authenticated.
   * @return {boolean} True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !__.IsNullOrUndefined(this.credentials);
  }

  isAuthorized(): boolean {
    return !__.IsNullOrUndefined(this.credentials) && this.credentials.permissions.length !== 0;
  }

  isAuthenticationExpired(): boolean {
    return this.credentials.expires < new Date();
  }

  /**
   * Gets the user credentials.
   * @return {Credentials} The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this._credentials;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param {Credentials=} credentials The user credentials.
   * @param {boolean=} remember True to remember credentials across sessions.
   */
  private setCredentials(credentials?: Credentials) {
    this._credentials = credentials || null;
    this._credentials$.next(credentials);

    if (credentials) {
      const storage = true ? localStorage : sessionStorage;
      storage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }
  }

  userIsAdmin(): boolean {
    return this.hasPermission("Admin");
  }

  userIsCandidate(): boolean {
    return this.hasPermission("User") && !this.hasPermission("Admin");
  }

  canAdminActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if((this.userIsAdmin())) {
      return true;
    } else {
        if(this.userIsCandidate()) {
          this.router.navigate([`/${AppRoute.Candidate}`]).then((value: boolean) => {
            return false;
          });          
        } else {
          this.router.navigate([`/${AppRoute.Forbidden}`]).then((value: boolean) => {
            return false;
          });
        }

    }
  }

  canCandidateActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if(this.userIsCandidate()) {
      return true;
    } else {
        if(this.userIsAdmin) {
          this.router.navigate([`/${AppRoute.Candidates}`]).then((value: boolean) => {
            this.loadingBarService.complete();
            return false;
          });          
        } else {
          this.router.navigate([`/${AppRoute.Forbidden}`]).then((value: boolean) => {
            this.loadingBarService.complete();
            return false;
          });
        }
    }
  }
}  

export const AdminGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(AuthenticationService).canAdminActivate(next, state);
}

export const CandidateGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(AuthenticationService).canCandidateActivate(next, state);
}