import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AppRoute } from '@app/shared/app.route.enum';
import { MbLoadingBarService } from '@montblancsimpl/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthenticationService, Credentials } from './authentication.service';

/**
 * The authentication guard ensures that the accessing user is an umc boarded user,
 * has at least one permissions for this application, has not an expired login and
 * additionally can check if the user is authorized by setting the data attribute
 * of a route to: data: { permissions: ['permission1'] }
 *
 * @export
 * @class AuthenticationGuard
 * @implements {CanActivate}
 */
@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private loadingBarService: MbLoadingBarService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (this.authenticationService.isAuthenticated()) {
      // The user has at least at one point requested access from the umc to this application

      if (this.authenticationService.isAuthorized()) {
        // user has at least one permission for this application

        if (this.authenticationService.isAuthenticationExpired()) {
          // User was authorized before, so we check if he still is
          return this.login();
        } else {
          // The user has a valid login, so we check if he has the required permissions for this route
          // if (environment.production === false) {
          //   return of(this.authenticationService.hasAnyPermission([environment.umcQATesterRole]));
          // }
          const allowed = this.authenticationService.hasAnyPermission(route.data['permissions']);
          if (!allowed) {
            this.navigateToForbidden();
          }
          return of(allowed);
        }
      } else {
        if (this.authenticationService.isAuthenticationExpired()) {
          return this.login();
        }
        // The user has zero permissions
        return of(false);
      }
    } else {
      // The user has never requested access from the umc before
      return this.login();
    }
  }

  private login(): Observable<boolean> {
    return this.authenticationService.login().pipe(
      map((credentials: Credentials) => {
        if (credentials.permissions.length === 0) {
          this.navigateToForbidden();
          return false;
        } else {
          if (this.authenticationService.userIsCandidate()) {
            this.router.navigate([`/${AppRoute.Candidate}`]).then((_v: boolean) => {
              this.loadingBarService.complete();
            });
          }
          if (this.authenticationService.userIsAdmin()) {
            this.router.navigate([`/${AppRoute.Candidates}`]).then((_v: boolean) => {
              this.loadingBarService.complete();
            }); 
          }
          return true;
        }
      })
    );
  }

  private navigateToForbidden() {
    this.router.navigate([`/${AppRoute.Forbidden}`]).then((value: boolean) => {
      this.loadingBarService.complete();
    });
  }
}
