import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthenticationService, CoreModule } from '@app/core';
import { environment } from '@env/environment';
import { MB_BASE_URL, MB_UMC_APPLICATION_KEY, MbLoadingBarModule, MbLoadingBarService } from '@montblancsimpl/core';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';

import { Router } from '@angular/router';
import { MsalInterceptor, MsalRedirectComponent } from '@azure/msal-angular';
import { MbAuthenticationModule } from '@montblancsimpl/authentication';
import { AppRoutingModule, ForbiddenComponent } from './app-routing.module';
import { AppComponent } from './app.component';
import { CandidateService } from './shared/services/candidate.service';
import { SharedModule } from './shared/shared.module';

// Registering to display currencies and dates in a german format
registerLocaleData(localeDe);

let authModule =  [ MbAuthenticationModule.forRoot(
  environment.mbBaseOAuthConfig
)]


if(!environment.auth) 
{
 authModule = []
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule, // This needs to be imported before the CORE module due to the http interceptors
    CoreModule,
    MbAuthenticationModule.forRoot(environment.mbBaseOAuthConfig),

    MbLoadingBarModule,

    // ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),

    MatMenuModule, // https://github.com/angular/material2/issues/10820

    SharedModule,
    TranslateModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 6000,
      preventDuplicates: true
    }),
    ...authModule,
    AppRoutingModule // This module needs to be imported as the last one
  ],
  declarations: [
    AppComponent,
    ForbiddenComponent
  ],
  providers: [
    MbLoadingBarService,
    CandidateService,
    { provide: MB_BASE_URL, useValue: environment.apiUrl + '/' },
    { provide: MB_UMC_APPLICATION_KEY, useValue: environment.umcApplicationKey },
    ...(environment.auth ? [{ provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true }] : []),
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
