import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { CandidateService } from '@app/shared/services/candidate.service';
import { SharedModule } from '@app/shared/shared.module';

import { CandidateHeaderComponent } from '../candidate-header/candidate-header.component';
import { CandidateLayoutComponent } from './candidate-layout.component';

/**
 * This module represents the export module for the base layout shell
 *
 * @export
 * @class ProductsListModule
 */
@NgModule({
  imports: [
    SharedModule,
    RouterModule,

    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    OverlayModule,
    MatMenuModule,
  ],
  declarations: [CandidateLayoutComponent, CandidateHeaderComponent],
  exports: [CandidateLayoutComponent],
  providers: [ CandidateService ]
})
export class CandidateLayoutModule { }
