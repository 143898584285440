import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wel-candidate-layout',
  templateUrl: './candidate-layout.component.html',
  styleUrls: ['./candidate-layout.component.scss']
})
export class CandidateLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
