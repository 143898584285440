import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';

import { HeaderComponent } from '../header/header.component';
import { BaseLayoutComponent } from './base-layout.component';
import { MatTooltipModule as MatTooltipModule } from '@angular/material/tooltip';
import { MbAuthenticationService } from '@montblancsimpl/authentication';

/**
 * This module represents the export module for the base layout shell
 *
 * @export
 * @class ProductsListModule
 */
@NgModule({
  imports: [
    SharedModule,
    RouterModule,

    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    OverlayModule,
    MatMenuModule,
    MatTooltipModule,    
  ],
  providers: [MbAuthenticationService],
  declarations: [BaseLayoutComponent, HeaderComponent],
  exports: [BaseLayoutComponent]
})
export class BaseLayoutModule { }
