<mb-loading-bar></mb-loading-bar>
<router-outlet></router-outlet>

<!-- FOOTER -->
<footer class="footer flex-row">
    <div class="flex-1-0-auto" style="text-align: center;">
      <a href="{{ getImprintLink() }}">
        {{ getImprintLabel() }}
      </a>
    </div>
  </footer>