import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { BaseLayoutModule } from '@app/layouts/base-layout/base-layout.module';
import { CandidateLayoutModule } from '@app/layouts/candidate-layout/candidate-layout.module';
import { TranslateModule } from '@ngx-translate/core';

import { AuthenticationGuard } from './authentication/authentication.guard';
import { AuthenticationService } from './authentication/authentication.service';
import { ApiPrefixInterceptor } from './http/api-prefix.interceptor';
import { AuthenticationCodeInterceptor } from './http/authentication-code.interceptor';
import { CacheInterceptor } from './http/cache.interceptor';
import { ErrorHandlerInterceptor } from './http/error-handler.interceptor';
import { HttpCacheService } from './http/http-cache.service';
import { HttpService } from './http/http.service';
import { LoadingInterceptor } from './http/loading.interceptor';
import { WithCredentialsInterceptor } from './http/with-credentials.interceptor';
import { I18nService } from './i18n.service';
import { RouteReusableStrategy } from './route-reusable-strategy';

@NgModule({
  imports: [CommonModule, HttpClientModule, TranslateModule, RouterModule, BaseLayoutModule, CandidateLayoutModule],
  providers: [
    AuthenticationService,
    AuthenticationGuard,
    I18nService,
    HttpCacheService,
    ApiPrefixInterceptor,
    ErrorHandlerInterceptor,
    CacheInterceptor,
    LoadingInterceptor,
    WithCredentialsInterceptor,
    AuthenticationCodeInterceptor,
    {
      provide: HttpClient,
      useClass: HttpService
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy
    }
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
