import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Adds the withCredentials http header to all requests
 */
@Injectable()
export class AuthenticationCodeInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authenticationCode = localStorage.getItem('authenticationCode');
    if (authenticationCode !== null) {
      request = request.clone({ headers: new HttpHeaders({'X-Authentication-Code': authenticationCode }) });
    }
    return next.handle(request);
  }
}
