import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { __ } from '@montblancsimpl/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Logger } from '../logger.service';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private toastr: ToastrService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
  }

  private errorHandler(response: HttpErrorResponse): Observable<HttpEvent<any>> {
    if (response.status === 0 || response.status === 503) {
      this.toastr.error(
        'It seems like the server is not accessible. Please make sure that you are connected to the internet.'
      );
    } else {
      if (!__.IsNullOrUndefined(response.error)) {
        if (response.error.code !== 400 && response.error.code !== 404) {
          this.toastr.error('An error occured. Please contact the administrator.');
        }
        if (!environment.production) {
          // Do something with the error
          log.error('Request error', response);
        }
      } else {
        this.toastr.error('An error occured. Please contact the administrator.');
      }
    }

    throw response;
  }
}
