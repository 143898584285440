import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page } from '../models/Page';
import { Document } from '../models/Document';
import { MbResponse } from '@montblancsimpl/core';

@Injectable()
export class CandidateService {

  resource = `candidate`;

  constructor(
    protected httpClient: HttpClient
  ) {
  }

  getLocale(): Observable<string> {
    return this.httpClient
        .get<MbResponse<string>>(
          `${this.resource}/locale`
        ).pipe(
            map((response: MbResponse<string>) => {
                return response.data;
            })
        );
  }

  getDocuments(): Observable<Document[]> {
    return this.httpClient
        .get<MbResponse<Document[]>>(
            `${this.resource}/documents`
        ).pipe(
            map((response: MbResponse<Document[]>) => {
                return response.data;
            })
        );
  }

  getPage(pageId: string): Observable<Page> {
    return this.httpClient
        .get<MbResponse<Page>>(
          `${this.resource}/page/${pageId}`
        ).pipe(
            map((response: MbResponse<Page>) => {
                return response.data;
            })
        );
  }

  getPages(): Observable<Page[]> {
    return this.httpClient
        .get<MbResponse<Page[]>>(
            `${this.resource}/pages`
        ).pipe(
            map((response: MbResponse<Page[]>) => {
                return response.data;
            })
        );
  }
}
