import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { I18nService, Logger } from '@app/core';
import { environment } from '@env/environment';
import { MbBaseComponent, MbLoadingBarService } from '@montblancsimpl/core';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

/**
 * This component represents the start of the application.
 * The setup of basic applications things, such as
 *
 *  - the logger,
 *  - i18n support,
 *  - browser title support,
 *  - and the navigation loading bar
 *
 * is handled here.
 *
 * @export
 * @class AppComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends MbBaseComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private loadingBarService: MbLoadingBarService
  ) {
    super();
  }

  ngOnInit() {
    // Logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    // I18n support
    this.i18nService.init('de-DE', ['de-DE', 'en-US']);

    // Loading bar on navigation
    this.router.events.subscribe((event: any): void => {
      this.navigationInterceptor(event);
    });

    // Browser title
    merge(
      this.translateService.onLangChange,
      this.router.events.pipe(filter(event => event instanceof NavigationEnd))
    )
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        const title = event['title'];
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });
  }

  getImprintLink(): string {
    return this.translateService.instant(`Terms and Conditions.Link`);
  }

  getImprintLabel(): string {
    return this.translateService.instant(`Terms and Conditions.Imprint`);
  }

  /**
   * Intercepts navigation events and starts or stops the loading bar, depending
   * on the intercepted event
   *
   * @private
   * @param {*} event The intercepted event
   * @memberof AppComponent
   */
  private navigationInterceptor(event: any): void {
    if (event instanceof NavigationStart) {
      this.loadingBarService.start();
    }
    if (event instanceof NavigationEnd) {
      this.completeLoadingAfterTime();
    }
    if (event instanceof NavigationCancel) {
      this.completeLoadingAfterTime();
    }
    if (event instanceof NavigationError) {
      this.completeLoadingAfterTime();
    }
  }

  /**
   * Delays the completion of the loading bar by 200ms to merge multiple
   * loading bar calls such as backend calls after the routing finished
   *
   * @private
   * @memberof AppComponent
   */
  private completeLoadingAfterTime() {
    setTimeout(() => {
      if (this.loadingBarService.pendingRequests <= 1) {
        this.loadingBarService.complete();
      }
    }, 200);
  }
}
