import { Route as ngRoute, Routes } from '@angular/router';
import { BaseLayoutComponent } from '@app/layouts/base-layout/base-layout.component';
import { AuthenticationGuard } from './authentication/authentication.guard';

// These can e.g. be umc permissions
export class ApplicationPermission {
  static Admin = 'Admin';
  static Candidate = 'User';
}

// Re-Exports permissions for further usage in the application
export class Permissions {
  static Application = ApplicationPermission;
}

/**
 * Provides helper methods to create routes.
 */
export class Route {
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return {Route} The new route using shell as the base.
   */
  static withBaseLayoutAndAuthentication(routes: Routes): ngRoute {
    return {
      path: '',
      component: BaseLayoutComponent,
      children: routes,
      canActivate: [AuthenticationGuard],
      data: { reuse: true }
    };
  }
}
